import React from 'react'
import PropTypes from 'prop-types'

import Verify from '../../components/Verify'
import { getParamFromURL } from '../../helpers'
import SEO from '../../components/SEO'

const DownloadInfo = ({ location }) => {
  const verifyToken = getParamFromURL(location.search, 'token')
  return (
    <>
      <SEO page="verifyEmail" />
      <Verify token={verifyToken} />
    </>
  )
}

DownloadInfo.propTypes = {
  location: PropTypes.object.isRequired,
}

export default DownloadInfo
