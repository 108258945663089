import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'

import Wrapper from '../Wrapper'
import { userVerify } from '../../reducers/verify'
import { LOGIN } from '../../data/constants'

const VerifyUser = ({ token }) => {
  const [isLoading, setLoading] = useState(true)
  const { loaded, isVerified, response, error } = useSelector(
    state => state.verify
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (token) dispatch(userVerify({ token })).finally(() => setLoading(false))
    if (!token) setLoading(false)
  }, [token])

  return (
    <Wrapper title="Verification">
      <div className="col-sm-6">
        <div className="cboxEraForm text-center mt-4">
          {!isLoading && !token && (
            <div>
              <i className="fas fa-unlink fa-3x text-danger"></i>
              <p className="pt-4 mb-1 font-weight-bold">Oops..!</p>
              <p className="font-weight-bold">Invalid Verification link.</p>
            </div>
          )}
          {isLoading && (
            <span>
              <i
                className="fas fa-cog fa-4x fa-spin mb-2"
                style={{ fontSize: '60px', color: 'blue' }}
                aria-hidden="true"
              />
              <p className="mt-3">Verification in Progress..</p>
            </span>
          )}
          {loaded && isVerified && (
            <span>
              <i
                className="far fa-check-circle fa-4x mb-2"
                style={{ fontSize: '60px', color: 'blue' }}
                aria-hidden="true"
              />
              <p className="mt-4 mb-1">{response.status} !</p>
              <p className="mt-2">
                You can{' '}
                <Link className="text-danger" to={LOGIN}>
                  Login now
                </Link>
              </p>
            </span>
          )}
          {error && loaded && !isVerified && (
            <span>
              <i
                className="fas fa-exclamation-circle fa-4x mb-2 text-danger"
                style={{ fontSize: '60px' }}
                aria-hidden="true"
              />
              <p className="mt-4 mb-1">Unable to verify your Email.</p>
              <p>Please contact support.</p>
            </span>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

VerifyUser.defaultProps = {
  token: null,
}

VerifyUser.propTypes = {
  token: PropTypes.string,
}

export default VerifyUser
